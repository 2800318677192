


























































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Contract extends Vue {
    get query() {
        return this.$route.query;
    }
}
